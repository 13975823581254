import { compact } from 'lodash';

import { getOrdersResponseParse } from '@/utils/order';

export const restaurantResponseParse = (response: DBRestaurant[]): Restaurant[] => {
  return (compact(response) || []).map(restaurant => ({
    id: restaurant.id,
    name: restaurant.name,
    address: restaurant.address,
    legalName: restaurant.legal_name,
    preciseAddress: restaurant.precise_address,
    registrationChannel: restaurant.registration_channel,
    isAvailableForChat: restaurant.is_available_for_chat,
    suppliers: restaurant.suppliers,
    supplierUsers: restaurant.supplier_users,
    favoriteProductCount: restaurant.favorite_product_count,
    favoriteProducts: restaurant.favorite_products,
    orders: getOrdersResponseParse(restaurant.__orders__),
    restaurantUserInvitations: restaurant.restaurant_user_invitations,
    restaurantChain: restaurant.restaurant_chain,
    createdAt: restaurant.created_at,
    updatedAt: restaurant.updated_at,
    hasOrders: restaurant.__has_orders__,
    salesTotal: restaurant.sales_total,
    ordersTotal: restaurant.orders_total,
    governmentId: restaurant.government_id,
    productsTotal: restaurant.products_total,
    supplierRestaurant: restaurant.suppliers_restaurants,
    lastOrderDate: restaurant.last_order_date,
    restaurantUsers: restaurant.__restaurant_users__,
    pictureUrl: restaurant.picture_url,
  }));
};
